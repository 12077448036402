.class-page {
    min-height: 100vh;
    background: #0a0a0a;
    color: white;
  }
  
  .class-hero {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  
  .class-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 0;
  }
  
  .class-content {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 0rem 0;
    width: 100%;
    max-width: 100%;
    margin: 0;
    background: rgba(10, 10, 10, 0.7);
    backdrop-filter: blur(5px);
  }
  
  .class-content h1 {
    font-size: 4rem;
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  
  .class-details {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .class-details p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 2rem;
  }
  
  .features-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .feature-group {
    border-radius: 8px;
    padding: 1rem;
    background: rgba(74, 158, 255, 0.05);
    border: 1px solid rgba(74, 158, 255, 0.1);
  }
  
  .trait-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .trait-header h3 {
    font-size: 1.5rem;
    color: #4a9eff;
    margin: 0;
  }
  
  .level {
    background: rgba(74, 158, 255, 0.2);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #4a9eff;
  }
  
  .trait p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
  }
  
  .spell-list {
    margin-top: 1rem;
  }
  
  .spell-level {
    margin: 0.5rem 0;
    font-size: 1.1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.8);
  }
  
  @media screen and (max-width: 768px) {
    .class-content h1 {
      font-size: 2.5rem;
    }
    
    .class-background {
      object-position: center;
    }
  }
  
  @media screen and (max-width: 480px) {
    .class-content h1 {
      font-size: 2rem;
    }
  }
  
  .class-brief {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9);
    margin: 1rem 0;
    padding: 0 1rem;
  }
  
  .content-section {
    margin-bottom: 3rem;
  }
  
  .content-section h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #fff;
  }
  
  .content-section p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .trait {
    margin-bottom: 1rem;
  }
  
  .trait:last-child {
    margin-bottom: 0;
  }
  
  .trait {
    margin-bottom: 1rem;
  }
  
  .trait:last-child {
    margin-bottom: 0;
  }
  
  .trait-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .trait-header h3 {
    font-size: 1.5rem;
    color: #4a9eff;
  }
  
  .level {
    background: rgba(74, 158, 255, 0.2);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #4a9eff;
  }
  
  .trait p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .spell-list {
    margin-top: 1rem;
  }
  
  .spell-level {
    margin: 0.5rem 0;
    font-size: 1.1rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .content-block {
    position: relative;
  }
  
  .level-indicator {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: #1e3a8a;
    color: #fff;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }
  
  .path-section {
    border-left: 2px solid #1e3a8a;
    margin: 2rem 0;
    padding-left: 1rem;
  }
  
  .path-title {
    color: #4a9eff;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .pantheon-title {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 2rem 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
  }
  
  .menu-container {
    margin-top: 1rem;
  }
  
  .requirement {
    color: #ff4444;
    font-style: italic;
    margin-bottom: 1rem;
    font-size: 1.1em;
  } 